import React, { useState } from "react";
import { getAuth, updateProfile } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  orderBy,
  query,
  updateDoc,
  where,
  setDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../../config/firebase";
import { useEffect, useCallback } from "react";
import Card from "react-bootstrap/Card";
import { Row, Col, Spinner } from "react-bootstrap";
import productsImg from "../../../assets/images/vendorproducts.png";
import cataloguesImg from "../../../assets/images/vendorcatalogues.png";
import videosImg from "../../../assets/images/vendorvideos.png";
import bestsellersImg from "../../../assets/images/vendorbestsellers.png";
import TextLimit from "../../TextLimit";
import nodpicon from "../../../assets/images/nodpicon.png";
import nocovericon from "../../../assets/images/nocovericon.png";


const RProfile = () => {

  const auth = getAuth();
  const navigate = useNavigate();
  
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };


  useEffect(() => {

  async function fetchUserData() {
    const userDocRef = doc(db, "usersrestaurants", auth.currentUser.uid);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      setUserData(userData);
    }
  }

  fetchUserData();
  setLoading(false);
}, [auth.currentUser.uid]);  


if (loading) {
  return <Spinner />;
}

 
  return (
    <Row>
      <Row>

        <Col md={12} className="vendorprofiletopright">
          <Row>
          <Col md={6}>
            <div className="vendorprofilecontainer">
              {userData && (
                <div>
                  {userData.profileImageUrl ? (
                    <img
                      src={userData.profileImageUrl}
                      alt="Logo"
                      className="invdlprofile-image-invdlcompanypage"
                    />
                  ) : (
                    <img
                      src={nodpicon}
                      alt="No Logo"
                      className="invdlprofile-image-invdlcompanypage"
                    />
                  )}
                </div>
              )}
            </div>
            </Col>
     

          <Col md={6}>
          <div className="vendorprofilecontainer">
          {userData && (
            <div>
              {userData.restQRimg ? (
                <img
                  src={userData.restQRimg}
                  className="invdlprofile-image-invdlcompanypage"
                  alt="Restaurant Cover"
                />
              ) : (
                <img
                  src={nocovericon}
                  alt="QR to Restaurant Page"
                  className="invdlprofile-image-invdlcompanypage"
                />
              )}
            </div>
          )}
          </div>
          </Col>
          </Row>

          <Row>
          <Col md={3}></Col>
          <Col md={6}>

            {userData && (
              <div>
              <Link to={`http://www.menusbyv.com/r/${userData.urlName}`} className="vlcustom-link">
                <h3 className="seepublic-heading">See how your Public Profile Looks like</h3>
                 
                </Link>
              </div>
            )}
          </Col>
          <Col md={3}></Col>
          </Row>
        
          <Row>
            {userData && (
              <div>
                <h2 className="vendorprofile-heading">{userData.restName}</h2>
              </div>
            )}
          </Row>

          <Row>
            {userData && (
              <div>
                <TextLimit
                  className="vendorprofiledescription"
                  text={userData.about}
                  limit={30}
                />
              </div>
            )}
          </Row>

          <Row>
            {userData && (
              <div>
                <p className="vendorprofiledescription">
                  Website: {userData.restWebsite}
                </p>
              </div>
            )}
          </Row>
          <Row>
            {userData && (
              <div>
                <p className="vendorprofiledescription">
                  Contact: {userData.contact}
                </p>
              </div>
            )}
          </Row>
          <Row>
            {userData && (
              <div>
                <p className="vendorprofiledescription">
                  Country: {userData.country}
                </p>
              </div>
            )}
          </Row>

          <Row>
            <div className="post-new-container">
              <button
                type="button"
                className="post-new-button"
                onClick={toggleDropdown}
              >
                Post New
              </button>
              {isDropdownOpen && (
                <div className="vendordropdown">
                  <Link
                    to={"addgeneralmenu"}
                    className="vendordropdown-link"
                  >
                    Add General Menu
                  </Link>
                  <Link
                    to={"addbranch"}
                    className="vendordropdown-link"
                  >
                    Add New Branch
                  </Link>
                </div>
              )}
            </div>
          </Row>
        </Col>
      </Row>

      <Row className="vendorlinkcards">
        <Col md={3}>
        {userData && (
          <Card className="vendorlcard">
            <Card.Img
              variant="top"
              loading="lazy"
              className="vendorlcardimg"
              src={productsImg}
              alt="MENU"
            />
            <div className="vlcard-text-overlay">
              <Card.Body>
                <Link to="mymenu" className="vlcustom-link">
                  <Card.Title>MENU</Card.Title>
                </Link>
              </Card.Body>
            </div>
          </Card>
           )}
        </Col>

        <Col md={3}>
        {userData && (
          <Card className="vendorlcard">
            <Card.Img
              variant="top"
              loading="lazy"
              className="vendorlcardimg"
              src={cataloguesImg}
              alt="BRANCHES"
            />
            <div className="vlcard-text-overlay">
              <Card.Body>
                <Link to="mybranches" className="vlcustom-link">
                  <Card.Title>BRANCHES</Card.Title>
                </Link>
              </Card.Body>
            </div>
          </Card>
           )}
        </Col>

        <Col md={3}>
          <Card className="vendorlcard">
            <Card.Img
              variant="top"
              loading="lazy"
              className="vendorlcardimg"
              src={videosImg}
              alt="VIDEOS"
            />
            <div className="vlcard-text-overlay">
              <Card.Body>
                <Link to="videos" className="vlcustom-link">
                  <Card.Title>REVIEWS</Card.Title>
                </Link>
              </Card.Body>
            </div>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="vendorlcard">
            <Card.Img
              variant="top"
              loading="lazy"
              className="vendorlcardimg"
              src={bestsellersImg}
              alt="BEST SELLERS"
            />
            <div className="vlcard-text-overlay">
              <Card.Body>
                <Link to="bestsellers" className="vlcustom-link">
                  <Card.Title>BEST SELLERS</Card.Title>
                </Link>
              </Card.Body>
            </div>
          </Card>
        </Col>
      </Row>
    </Row>
  );
};

export default RProfile;
