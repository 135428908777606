import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../../Spinner";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import {  doc, getDoc, setDoc, addDoc, collection, serverTimestamp, getDocs } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import Select from "react-select"; // Import react-select
import Container from 'react-bootstrap/Container';
import { Row, Col } from "react-bootstrap";
import qrcode from 'qrcode';

const AddRBranch = () => {

  const auth = getAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  
  
  const [formData, setFormData] = useState({
    restaurantName: "",
    uidreference: "",
    urlName: "",
    branchName: "",
    branchUrl: "",
    branchQRimg: "",
    branchQRcode: "",
  });
  

  const { 
    restaurantName, 
    uidreference,
    urlName,
    branchName,
    branchUrl,
    branchQRimg,
    branchQRcode,
} = formData; 


  function onChange(e) {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  }

useEffect(() => {
  const fetchUserData = async () => {
    // Fetch user data based on the user's UID (auth.currentUser.uid)
    const userDocRef = doc(db, "usersrestaurants", auth.currentUser.uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      // If user data exists, update the userData state
      setUserData(userDocSnapshot.data());
    }
  };

  fetchUserData();
}, [auth.currentUser.uid]);


async function copySubcollections(sourceDocRef, targetDocRef, subcollectionNames) {

  try {
    for (const subcollectionName of subcollectionNames) {
      const sourceSubcollectionRef = collection(sourceDocRef, subcollectionName);
      const targetSubcollectionRef = collection(targetDocRef, subcollectionName);

      const subcollectionDocsSnapshot = await getDocs(sourceSubcollectionRef);

      for (const docSnapshot of subcollectionDocsSnapshot.docs) {
        await setDoc(doc(targetSubcollectionRef, docSnapshot.id), docSnapshot.data());
      }
    }
  } catch (error) {
    console.error("Error copying subcollections:", error);
    throw error;
  }
};


  
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);



    const formDataCopy = {
      ...formData,
      restaurantName:  userData?.restName || "",
      uidreference: auth.currentUser.uid,
      urlName: userData?.urlName || "",
      branchName: branchName,  
      branchUrl: branchName.toLowerCase().replace(/\s+/g, ''),
      timestamp: serverTimestamp(),
      branchQRcode,
      branchQRimg,
    };

    formDataCopy.branchQRcode = "https://www.menusbyv.com/r/" + formDataCopy.urlName + "/branch/" + formDataCopy.branchUrl;
    formDataCopy.branchQRimg = await qrcode.toDataURL(formDataCopy.branchQRcode);


    
    try {
        // Add the restaurant document to a subcollection based on its name
    const restaurantCollectionRef = collection(db, "restaurants");
    const restaurantDocRef = doc(restaurantCollectionRef, "doccontainingallRestaurants");
    const subcollectionRef = collection(restaurantDocRef, formDataCopy.urlName);

    // Add the document within the subcollection as 'generalMenu'
    const generalMenuDocRef = doc(subcollectionRef, formDataCopy.branchUrl);
    
    // Check if the branch url already exists
    const branchDocSnapshot = await getDoc(generalMenuDocRef);

    if (branchDocSnapshot.exists()) {
      setLoading(false);
      toast.warn("Branch name already exists. Try a different name or add numbering to it.");
    } else {
      // Add the document within the subcollection
      await setDoc(generalMenuDocRef, {
        ...formDataCopy,
      });

        // Copy the generalMenu document to the new branch's document as generalBmenu in branchMenu collection
        const generalMenuRef = doc(subcollectionRef, "generalMenu");
        const generalMenuSnapshot = await getDoc(generalMenuRef);

        if (generalMenuSnapshot.exists()) {
          const generalMenuData = generalMenuSnapshot.data();
          const branchMenuCollectionRef = collection(generalMenuDocRef, "branchMenu");
          const branchGeneralMenuDocRef = doc(branchMenuCollectionRef, "generalBmenu");

          await setDoc(branchGeneralMenuDocRef, generalMenuData);

            // Copy subcollections from generalMenu to branchGeneralMenuDocRef
            const subcollectionNames = generalMenuData.sections.map(section => section.name);

            await copySubcollections(generalMenuRef, branchGeneralMenuDocRef, subcollectionNames);
          }

    setLoading(false);
    toast.success("Branch added successfully");
        // Reset the form data after successful submission
  setFormData({
    restaurantName: "",
    uidreference: "",
    urlName: "",
    branchName: "",
    branchUrl: "",
    branchQRimg: "",
    branchQRcode: "",

    });
  }
  
    } catch (error) {
      console.error("Error adding restaurant branch:", error);
    setLoading(false);
    toast.error("Error adding branch");

  }
}

  if (loading) {
    return <Spinner />;
  }
  return (
    <Container fluid="true" className="mainpage">
        <Row>
            <Col>
                <h1 className="firstheading">Add a Branch</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <h2 className="secondheading">Details of the Branch</h2>
            </Col>
        </Row>

        <Row>
        <Col md={2}></Col>
        <Col md={8} className="signupleftcolumn">
          <form onSubmit={handleSubmit}>
            {userData && (
              <p className="addproj-subheading">Restaurant Name: {userData.restName}</p>
            )}

                  <div div className="form-group">
                      <p className="signup-subheading">Branch Name</p>
                      <input
                        id="branchName"
                        type="text"
                        name="description"
                        className="email"
                        value={branchName}
                        onChange={onChange}
                        required
                      />
                    </div>


            <button
              type="submit"
              className="finalsubmit-button"
            >
              Submit
            </button>
          </form>

          <p>Note: Menu will be displayed publicly after approval of admin.</p>
        </Col>
        <Col md={2}></Col>
      </Row>

</Container>

)

}

export default AddRBranch;


