import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Outlet } from "react-router-dom";
import NavScrollExample from "./Navbar";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function AppBody(){
    return ( 
        <>

    <Container fluid="true" className="content-container">
        <Row>
            <Col>
            <NavScrollExample />
            </Col>
        </Row>
   
        <Row>
            <Col >
                <Outlet />
            </Col>
        </Row>

  
   
        <Row>
            <Col >
       
    <Footer />
            </Col>
        </Row>

    </Container>


    <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
   </>
    )
};


export default AppBody;

