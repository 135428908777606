import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {Row, Col} from "react-bootstrap";



const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  function onChange(e) {
    setEmail(e.target.value);
  }
  async function onSubmit(e) {
    e.preventDefault();
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      toast.success("Email was sent");
    } catch (error) {
      toast.error("Could not sent reset password");
    }
  }
  return (
    <Row>
        <div className="signinupcontainer">
 
 <Col md={6}>
 <h3 className="sign-up-header">Forgot Password</h3>

          <form onSubmit={onSubmit}>
            <div className="email-section">
              <input
                className="email"
                type="email"
                id="email"
                placeholder="Email Address"
                required={true}
                value={email}
                onChange={onChange}
              />
            </div>
            

            <div className="signin-section">
   
              <p className="email-section">
                <Link
                  to="/sign-in"
                  className="signin"
                >
                  Sign In Instead
                </Link>
              </p>
            </div>
            <button
              className="signin-button"
              type="submit"
            >
              Send Reset Password
            </button>

          </form>
  
          </Col>
      </div>
    </Row>
  );
};

export default ForgotPassword;
