import React, { useState } from "react";
import { toast } from "react-toastify";
// import Spinner from "../Spinner";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
// import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import Container from 'react-bootstrap/Container';
import { Row, Col } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import {
    addDoc,
    collection,
    serverTimestamp,
  } from "firebase/firestore";
  import { db } from "../../config/firebase";
import { doc,setDoc } from "firebase/firestore";
import qrcode from 'qrcode';
import "../../styles/styles.css"
import './AdminPanel.css';


function AdminAddRestaurant() {

    // const auth = getAuth();
  
    const [logoImageFile, setLogoImageFile] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const [formData, setFormData] = useState({
      restName: "",
      urlName: "",
      restType: "",
      restWebsite: "",
      logoimage: {},
      restPriceavg: "",
      restQRimg: "",
      restQRcode: "",
      restRating: "",
      restRatingoutof: "",
      restNoofReviews: "",
      restNoofBranches: "",
      restCountry: "",
      restCities: "",
      restContactone: "",
      restContacttwo: "",
      restEmail: "",
      restHQAddress: "",
      restInsta: "",
      restFb: "",
      restX: "",
      restWhatsapp: "",
    });

    const navigate = useNavigate();

    const { 
        restName, 
        urlName,
        restType, 
        restWebsite, 
        logoimage,
        restQRimg,
        restQRcode,
        restPriceavg,
        restCountry,
        restCities,
        restContactone,
        restContacttwo,
        restWhatsapp,
        restInsta,
        restFb,
        restX,
        restEmail,
        restHQAddress,
        restNoofBranches,
        restRating,
        restRatingoutof,
        restNoofReviews,
    } = formData; 


  function onChange(e) {
    let boolean = null;
    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }
    // Files
    if (e.target.files) {
        // Handle logoimage separately
        setLogoImageFile(e.target.files[0]);
    }

    
    // Text/Boolean/Number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  }

  

  

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    let logoImageUrl = "";
    if (logoImageFile) {
      const storage = getStorage();
    //   const filename = `restaurantLogos/${auth.currentUser.uid}-${logoImageFile.name}-${uuidv4()}`;
      const filename = `restaurantLogos/${logoImageFile.name}-${uuidv4()}`;
      const storageRef = ref(storage, filename);
      const uploadTask = uploadBytesResumable(storageRef, logoImageFile);

      try {
        await uploadTask;
        logoImageUrl = await getDownloadURL(uploadTask.snapshot.ref);
      } catch (error) {
        setLoading(false);
        toast.error("Logo image upload failed");
        return;
      }
    }

    
    const formDataCopy = {
      ...formData,
      logoimage: logoImageUrl, // Store the logo image download URL    
      urlName: restName.toLowerCase().replace(/\s+/g, ''), 
      timestamp: serverTimestamp(),
      restNoofBranches: 0,
      restRating: 0,
      restRatingoutof: 5,
      restNoofReviews: 0,
      restQRimg,
      restQRcode,
      //   userRef: auth.currentUser.uid,
    };
    

      try {
          // Add the restaurant document to a subcollection based on its name
      const restaurantCollectionRef = collection(db, "restaurants");
      const restaurantDocRef = doc(restaurantCollectionRef, "doccontainingallRestaurants");
      const subcollectionRef = collection(restaurantDocRef, formDataCopy.urlName);

      // Add the document within the subcollection as 'infoDoc'
      const infoDocRef = doc(subcollectionRef, 'infoDoc');

      formDataCopy.restQRcode = "https://www.menusbyv.com/r/" + formDataCopy.urlName;
      formDataCopy.restQRimg = await qrcode.toDataURL(formDataCopy.restQRcode);

      await setDoc(infoDocRef, {
        ...formDataCopy,
        // Add additional fields if needed
      });

      setLoading(false);
      toast.success("Restaurant added successfully");
          // Reset the form data after successful submission
    setFormData({
        restName: "",
        urlName: "",
        restType: "",
        restWebsite: "",
        logoimage: "",
        restPriceavg: "",
        restCountry: "",
        restCities: "",
        restContactone: "",
        restContacttwo: "",
        restWhatsapp: "",
        restInsta: "",
        restFb: "",
        restX: "",
        restEmail: "",
        restHQAddress: "",
        restNoofBranches: "",
        restRating: "",
        restRatingoutof: "",
        restNoofReviews: "",
        restQRimg: "",
        restQRcode: "",
      });
      } catch (error) {
        console.error("Error adding restaurant: ", error);
        setLoading(false);
        toast.error("Error adding restaurant");
      }
    }

//   if (loading) {
//     return <Spinner />;
//   }


    return (
        <Container fluid="true" className="mainpage">
        <Row>
            <Col>
                <h1 className="firstheading">Add a New Restaurant</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <h2 className="secondheading">General Details of the Restaurant</h2>
            </Col>
        </Row>

        <Row>
          <form onSubmit={handleSubmit}>
            <p className="addproj-subheading">Restaurant Name</p>
            <input
              type="text"
              id="restName"
              value={restName}
              onChange={onChange}
              placeholder="Restaurant Name"
              required
              className="fieldtextarea"
            />
            <p className="addproj-subheading">Restaurant Price Average</p>
            <input
              type="text"
              id="restPriceavg"
              value={restPriceavg}
              onChange={onChange}
              placeholder="Restaurant Price Average"
              required
              className="fieldtextarea"
            />
            <p className="addproj-subheading">Country</p>
            <input
              type="text"
              id="restCountry"
              value={restCountry}
              onChange={onChange}
              placeholder="Country"
              required
              className="fieldtextarea"
            />
            <p className="addproj-subheading">Cities</p>
            <input
              type="text"
              id="restCities"
              value={restCities}
              onChange={onChange}
              placeholder="Cities"
              required
              className="fieldtextarea"
            />

            <p className="addproj-subheading">Restaurant Type</p>
            <input
              type="text"
              id="restType"
              value={restType}
              onChange={onChange}
              placeholder="Restautant Type"
              required
              className="fieldtextarea"
            />

            <p className="addproj-subheading">Restaurant Website</p>
            <input
              type="text"
              id="restWebsite"
              value={restWebsite}
              onChange={onChange}
              placeholder="Restaurant Website"
              required
              className="fieldtextarea"
            />
            <p className="addproj-subheading">Contact Number</p>
            <input
              type="text"
              id="restContactone"
              value={restContactone}
              onChange={onChange}
              placeholder="Contact Number"
              required
              className="fieldtextarea"
            />
            <p className="addproj-subheading">Alternate Contact Number</p>
            <input
              type="text"
              id="restContacttwo"
              value={restContacttwo}
              onChange={onChange}
              placeholder="Alternate Contact Number"
              required
              className="fieldtextarea"
            />
            <p className="addproj-subheading">Whatsapp Number</p>
            <input
              type="text"
              id="restWhatsapp"
              value={restWhatsapp}
              onChange={onChange}
              placeholder="Whatsapp Number"
              required
              className="fieldtextarea"
            />
            <p className="addproj-subheading">Instagram</p>
            <input
              type="text"
              id="restInsta"
              value={restInsta}
              onChange={onChange}
              placeholder="Instagram"
              required
              className="fieldtextarea"

            />
            <p className="addproj-subheading">Facebook</p>
            <input
              type="text"
              id="restFb"
              value={restFb}
              onChange={onChange}
              placeholder="Facebook"
              required
              className="fieldtextarea"
            />
            <p className="addproj-subheading">X (Twitter)</p>
            <input
              type="text"
              id="restX"
              value={restX}
              onChange={onChange}
              placeholder="X (Twitter)"
              required
              className="fieldtextarea"
            />
            <p className="addproj-subheading">Email</p>
            <input
              type="text"
              id="restEmail"
              value={restEmail}
              onChange={onChange}
              placeholder="Email"
              required
              className="fieldtextarea"
            />
            <p className="addproj-subheading">Headquarters Address</p>
            <input
              type="text"
              id="restHQAddress"
              value={restHQAddress}
              onChange={onChange}
              placeholder="Headquarters Address"
              required
              className="fieldtextarea"
            />

            <p className="addproj-subheading">Logo Image</p>
            <input
              type="file"
              id="logoimage"
              onChange={onChange}
               accept=".jpg, .png, .jpeg"
              required
              className="finaimagesinput"
            />


            <button type="submit" className="finalsubmit-button">
              Submit
            </button>
          </form>
        </Row>

 

    </Container>

    )

}

export default AdminAddRestaurant;







