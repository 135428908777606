// src/components/RestaurantsList.js
import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase"; // Adjust the import based on your project structure
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from "../Spinner";
import RestMenuCard from "./RestMenuCard"; // Import the new RestMenuCard component

const MenusPage = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "usersrestaurants"));
        const restaurantsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRestaurants(restaurantsList);
      } catch (error) {
        console.error("Error fetching restaurants: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurants();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container>
      <Row>
      <div className="restaurants-container">
        {restaurants.map((restaurant) => (
          <Col key={restaurant.id} md={4} className="mb-4">
            <RestMenuCard restaurant={restaurant} id={restaurant.id} />
          </Col>
        ))}
      </div>
      </Row>
    </Container>
  );
};

export default MenusPage;
