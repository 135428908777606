import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

function SearchPage() {

    const navigate = useNavigate();

    return (
        <Container fluid="true" className="mainpage">
        <Row>
            <Col>
                <h1 className="firstheading">Admin Panel</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <h2 className="secondheading">Menus By V</h2>
            </Col>
        </Row>


                <Row>
                    <Col>
                        <Button onClick={() => navigate('/searchrestaurant')} variant="outline-dark">Search for Restaurants</Button>
                        <Button onClick={() => navigate('/searchfooditem')} variant="outline-dark">Search for Food Item</Button>
                    </Col>
                </Row>
      
 

    </Container>

    )

}

export default SearchPage;






