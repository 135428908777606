import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import the auth function explicitly


console.log(process.env.REACT_APP_PROJECT_ID);



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//add firebase cloud
// Your web app's Firebase configuration
const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID,

apiKey: "AIzaSyBeC2yNP7YQ0W79onIGwMnM2rMKzFmjeE4",
authDomain: "menusbyv-37970.firebaseapp.com",
projectId: "menusbyv-37970",
storageBucket: "menusbyv-37970.appspot.com",
messagingSenderId: "571364688031",
appId: "1:571364688031:web:2bd92a661fb2b54c24e2aa",
measurementId: "G-8RQZEJ9M1D"
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore();
export const auth = getAuth(app); // Use the getAuth function to initialize auth

export default app; // Export the firebase app









