import React, { useState } from 'react';

function TextLimit({ text, limit }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const words = text.split(' ');

  const limitedText = isExpanded ? text : words.slice(0, limit).join(' ');

  return (
    <div>
      <p>
        {limitedText}
        {!isExpanded && words.length > limit && ' ...'}
      </p>
      {words.length > limit && (
        <button onClick={() => setIsExpanded(!isExpanded)} className='readmoreshowless'>
          {isExpanded ? 'Show Less' : 'Read More'}
        </button>
      )}
    </div>
  );
}

export default TextLimit;
