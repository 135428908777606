import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import mainpageImage from './../assets/images/mainpageimage.png';
import mainpagerestlocator from "./../assets/images/mainpagerestlocator.png";
import mainpagereviews from "./../assets/images/mainpagereviews.png";
import mainpagemenus from "./../assets/images/mainpagemenus.png";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function MainPage() {
  const navigate = useNavigate();

  return (
    <Container fluid="true" className="mainpage">
      <Row>
        <Col>
          <h1 className="firstheading">Menus By V</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="secondheading">The Food Universe</h2>
        </Col>
      </Row>
      {/* <Row>
        <Col xs={1} md={1}>
            </Col>
            <Col xs={10} md={10}>
                <img src={mainpageImage} className="mainpageimage" alt="title" ></img>
            </Col>
            <Col xs={1} md={1}>
            </Col>
        </Row> */}
      {/* <Row>
        <Col xs={1} md={1}>
            </Col>
            <Col xs={10} md={10}>
                <h3 className="mainpagetext">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet magna dolor. Vivamus ut lacus eu massa imperdiet pharetra. Quisque ac risus porta, ultrices arcu vitae, convallis felis. Morbi malesuada at est vel gravida. Donec ornare consequat nunc sit amet tempor.</h3>
            </Col>
            <Col xs={1} md={1}>
            </Col>
        </Row> */}
      <Row>
        <Col xs={1} md={1}></Col>
        <Col xs={10} md={5}>
          <img
            src={mainpagerestlocator}
            className="mainpageimage"
            alt="title"
          ></img>
        </Col>
        <Col className="mainpagetextcol" xs={10} md={5}>
          <h3 className="sectionheading">Restaurant Locator</h3>

          <h3 className="mainpagetext">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet
            magna dolor. Vivamus ut lacus eu massa imperdiet pharetra. Quisque
            ac risus porta, ultrices arcu vitae, convallis felis. Morbi
            malesuada at est vel gravida. Donec ornare consequat nunc sit amet
            tempor.
          </h3>
        </Col>
        <Col xs={1} md={1}></Col>
      </Row>
      <Row>
        <Col xs={1} md={1}></Col>
        <Col className="mainpagetextcol" xs={10} md={5}>
          <h3 className="sectionheading">Reviews</h3>

          <h3 className="mainpagetext">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet
            magna dolor. Vivamus ut lacus eu massa imperdiet pharetra. Quisque
            ac risus porta, ultrices arcu vitae, convallis felis. Morbi
            malesuada at est vel gravida. Donec ornare consequat nunc sit amet
            tempor.
          </h3>
        </Col>
        <Col xs={10} md={5}>
          <img
            src={mainpagereviews}
            className="mainpageimage"
            alt="title"
          ></img>
        </Col>

        <Col xs={1} md={1}></Col>
      </Row>
      <Row>
        <Col xs={1} md={1}></Col>
        <Col xs={10} md={5}>
          <img src={mainpagemenus} className="mainpageimage" alt="title"></img>
        </Col>
        <Col className="mainpagetextcol" xs={10} md={5}>
          <h3 className="sectionheading">Menus</h3>
          <h3 className="mainpagetext">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet
            magna dolor. Vivamus ut lacus eu massa imperdiet pharetra. Quisque
            ac risus porta, ultrices arcu vitae, convallis felis. Morbi
            malesuada at est vel gravida. Donec ornare consequat nunc sit amet
            tempor.
          </h3>
        </Col>
        <Col xs={1} md={1}></Col>
      </Row>
      <Row></Row>
    </Container>
  );
}

export default MainPage;
