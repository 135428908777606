import React from 'react';
import Nav from 'react-bootstrap/Nav';
import './AdminPanel.css';

const Sidebar = ({ onSelectPage, selectedPage }) => {
  return (
    <Nav className="flex-column adminsidebar-nav">
      <Nav.Link
        onClick={() => onSelectPage('admin-home')}
        active={selectedPage === 'admin-home'}
      >
        Admin Home Page
      </Nav.Link>
      <Nav.Link
        onClick={() => onSelectPage('addrestaurant')}
        active={selectedPage === 'addrestaurant'}
      >
        Add Restaurants
      </Nav.Link>
      
    </Nav>
  );
};

export default Sidebar;
