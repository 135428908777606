import React from "react";
import spinner from "../../src/assets/preloader.svg";
const Spinner = () => {
  return (
    <div
      className="spinnermain" >
      <img src={spinner} alt="Loading..." className="spinnerimg"></img>
    </div>
  );
};

export default Spinner;
