import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import { Row, Col } from "react-bootstrap";
import Spinner from "../../Spinner";

const RBranchItem = () => {
  const { branchName, sectionName, itemId } = useParams();
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("No user is signed in");
          setLoading(false);
          return;
        }

        const userDocRef = doc(db, "usersrestaurants", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        if (!userDocSnapshot.exists()) {
          console.error("User data not found");
          setLoading(false);
          return;
        }

        const restaurantName = userDocSnapshot.data().urlName;
        const itemDocRef = doc(db, "restaurants", "doccontainingallRestaurants", restaurantName, branchName, "branchMenu", "generalBmenu", sectionName, itemId);
        const itemDocSnapshot = await getDoc(itemDocRef);

        if (itemDocSnapshot.exists()) {
          setItemData(itemDocSnapshot.data());
        }
      } catch (error) {
        console.error("Error fetching item data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchItemData();
  }, [auth, branchName, sectionName, itemId]);

  if (loading) {
    return <Spinner />;
  }

  if (!itemData) {
    return (
      <Container fluid="true" className="mainpage">
        <Row>
          <Col>
            <h1 className="firstheading">Item Not Found</h1>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid="true" className="mainpage">
      <Row>
        <Col md={6}>
          <Carousel>
            {itemData.topView && (
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={itemData.topView}
                  alt="Top View"
                />
                <Carousel.Caption>
                  <p>Top View</p>
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {itemData.frontView && (
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={itemData.frontView}
                  alt="Front View"
                />
                <Carousel.Caption>
                  <p>Front View</p>
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {itemData.sideView && (
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={itemData.sideView}
                  alt="Side View"
                />
                <Carousel.Caption>
                  <p>Side View</p>
                </Carousel.Caption>
              </Carousel.Item>
            )}
          </Carousel>
        </Col>
        <Col md={6}>
          <h1 className="firstheading">{itemData.name}</h1>
          <p className="item-price">Price: ${itemData.price}</p>
          <p className="item-description">{itemData.description}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default RBranchItem;