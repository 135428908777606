import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, getDocs, listCollections } from "firebase/firestore";
import { db } from "../../config/firebase";
import Spinner from "../Spinner";
import { useParams, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { Row, Col } from "react-bootstrap";

const RGeneralMenuPage = () => {

    const params = useParams();
    const restaurantName = params.restaurantName;
    const [loading, setLoading] = useState(true);
    const [menuData, setMenuData] = useState([]);


  useEffect(() => {
    const fetchMenuData = async () => {
  
        try {
            // Reference to the restaurant's general menu document
            const generalMenuDocRef = doc(db, "restaurants", "doccontainingallRestaurants", restaurantName, "generalMenu");
            const generalMenuDocSnapshot = await getDoc(generalMenuDocRef);
    
            if (generalMenuDocSnapshot.exists()) {
              const sections = generalMenuDocSnapshot.data().sections || [];

               // Sort sections by sectionNumber
              sections.sort((a, b) => a.sectionNumber - b.sectionNumber);


              const sectionsData = [];
    
              for (const section of sections) {
                const sectionName = section.name; // Ensure sectionName is a string
                const sectionOriginalName = section.originalName;
                const topNote = section.topNote;
                const bottomNote = section.bottomNote;
                const sectionItemsSnapshot = await getDocs(collection(generalMenuDocRef, sectionName));
                const items = sectionItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
                sectionsData.push({ sectionName, sectionOriginalName, topNote, bottomNote, items });
              }
    
              setMenuData(sectionsData);
            }
          } catch (error) {
            console.error("Error fetching menu data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchMenuData();
      }, [restaurantName]);
    
      if (loading) {
        return <Spinner />;
      }
  return (
    <Container fluid="true" className="mainpage">
      <Row>
        <Col>
          <h1 className="firstheading">Restaurant Menu</h1>
        </Col>
      </Row>
      {menuData.length > 0 ? (
        menuData.map((section, sectionIndex) => (
          <Row key={sectionIndex} className="menu-section">
            <Col md={12}>
              <h2 className="section-title">{section.sectionOriginalName}</h2>
              {section.topNote && (
                <p className="section-note top-note">{section.topNote}</p>
              )}
              {section.items.map((item, itemIndex) => (
                <div key={itemIndex} className="menu-item">
                  <h3 className="item-name">
                    <Link className="item-name-link" to={`/r/${restaurantName}/menu/${section.sectionName}/${item.id}`}>{item.name}</Link>
                  </h3>
                  <p className="item-price">{item.nameExtra}</p>
                  <p className="item-price">Price: ${item.price}</p>
                  <p className="item-description">{item.description}</p>
                  <p className="item-description">{item.descriptionExtra}</p>
                  <p className="item-price">Rating: {item.itemRating} ({item.itemRatingBy})</p>
                  <p className="item-price">Taste Category: {item.tasteCategory}</p>
                  <p className="item-price">Price Range: {item.itemPriceRange}</p>
                  <p className="item-price">Type: {item.itemType}</p>
                  <p className="item-price">Food Allergies (If Any): {item.foodAllergies}</p>
                </div>
              ))}
              {section.bottomNote && (
                <p className="section-note bottom-note">{section.bottomNote}</p>
              )}
            </Col>
          </Row>
        ))
      ) : (
        <Row>
          <Col>
            <p>No menu data available.</p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default RGeneralMenuPage;
