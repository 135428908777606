import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../../Spinner";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import {
  doc,
  getDoc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import Select from "react-select"; // Import react-select
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";

const AddRGeneralMenu = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const [formData, setFormData] = useState({
    restaurantName: "",
    uidreference: "",
    urlName: "",
  });

  const [sections, setSections] = useState([{ 
    name: "", 
    originalName: "", 
    sectionNumber: "", 
    topNote: "",
    bottomNote: "",
    items: [{ name: "", price: "", itemRating: 0, itemRatingBy: 0, itemPriceRange: "", tasteCategory: "", itemType: "", nameExtra: "", descriptionExtra: "", positioninSection: "", description: "", foodAllergies: "", topView: null, frontView: null, sideView: null }] 
  }]);


  const { restaurantName, uidreference, urlName } = formData;

  useEffect(() => {
    const fetchUserData = async () => {
      // Fetch user data based on the user's UID (auth.currentUser.uid)
      const userDocRef = doc(db, "usersrestaurants", auth.currentUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        // If user data exists, update the userData state
        setUserData(userDocSnapshot.data());
      }
    };

    fetchUserData();
  }, [auth.currentUser.uid]);

  function handleSectionChange(index, event) {
    const { name, value } = event.target;
    const newSections = [...sections];

    if (name === "name") {
      newSections[index].originalName = value;
    }
    newSections[index][name] = value;
    setSections(newSections);
  }

  function handleItemChange(sectionIndex, itemIndex, event) {
    const newSections = [...sections];
    newSections[sectionIndex].items[itemIndex][event.target.name] =
      event.target.value;
    setSections(newSections);
  }

  function handleFileChange(sectionIndex, itemIndex, view, file) {
    const newSections = [...sections];
    newSections[sectionIndex].items[itemIndex][view] = file;
    setSections(newSections);
  }

  function addSection() {
    setSections([...sections, { 
      name: "", 
      originalName: "", 
      sectionNumber: "", 
      topNote: "",
      bottomNote: "",
      items: [{ name: "", price: "", itemRating: 0, itemRatingBy: 0, itemPriceRange: "", tasteCategory: "", itemType: "", nameExtra: "", descriptionExtra: "", positioninSection: "", description: "", foodAllergies: "", topView: null, frontView: null, sideView: null }] 
    }]);
  }

  function addItem(sectionIndex) {
    const newSections = [...sections];
    newSections[sectionIndex].items.push({ name: "", price: "", itemRating: 0, itemRatingBy: 0, itemPriceRange: "", tasteCategory: "", itemType: "", nameExtra: "", descriptionExtra: "", positioninSection: "", description: "", foodAllergies: "", topView: null, frontView: null, sideView: null });
    setSections(newSections);
  }

  // function onChange(e) {
  //   let boolean = null;
  //   if (e.target.value === "true") {
  //     boolean = true;
  //   }
  //   if (e.target.value === "false") {
  //     boolean = false;
  //   }

  //   // Text/Boolean/Number
  //   if (!e.target.files) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [e.target.id]: boolean ?? e.target.value,
  //     }));
  //   }
  // }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const formDataCopy = {
      ...formData,
      restaurantName: userData?.restName || "",
      uidreference: auth.currentUser.uid,
      urlName: userData?.urlName || "",
      timestamp: serverTimestamp(),
    };

    try {
      // Add the restaurant document to a subcollection based on its name
      const restaurantCollectionRef = collection(db, "restaurants");
      const restaurantDocRef = doc(
        restaurantCollectionRef,
        "doccontainingallRestaurants"
      );
      const subcollectionRef = collection(
        restaurantDocRef,
        formDataCopy.urlName
      );

      // Add the document within the subcollection as 'generalMenu'
      const generalMenuDocRef = doc(subcollectionRef, "generalMenu");

      // Fetch the existing generalMenu document
      const generalMenuDocSnapshot = await getDoc(generalMenuDocRef);
      let existingSections = [];
      if (generalMenuDocSnapshot.exists()) {
        existingSections = generalMenuDocSnapshot.data().sections || [];
      }

      const newSections = sections.map((section) => ({
        name: section.name.toLowerCase().replace(/\s+/g, ""),
        originalName: section.originalName,
        sectionNumber: section.sectionNumber,
        topNote: section.topNote,
        bottomNote: section.bottomNote,
      }));

      const updatedSections = [...existingSections, ...newSections];

      await setDoc(generalMenuDocRef, {
        ...formDataCopy,
        sections: updatedSections,
      });

      // Iterate through sections and items to add them to Firestore
      for (const section of sections) {
        const sectionCollectionRef = collection(
          generalMenuDocRef,
          section.name.toLowerCase().replace(/\s+/g, "")
        );

        for (const item of section.items) {
          const itemDocRef = doc(sectionCollectionRef);
          const itemData = {
            name: item.name,
            price: item.price,
            itemPriceRange: item.itemPriceRange,
            tasteCategory: item.tasteCategory,
            itemType: item.itemType, 
            nameExtra: item.nameExtra,
            descriptionExtra: item.descriptionExtra,
            foodAllergies: item.foodAllergies,
            positioninSection: item.positioninSection,
            description: item.description,
            itemRating: item.itemRating,
            itemRatingBy: item.itemRatingBy,
            timestamp: serverTimestamp(),
          };

          const uploadFile = async (file, path) => {
            const storage = getStorage();
            const fileRef = ref(storage, path);
            await uploadBytesResumable(fileRef, file);
            return getDownloadURL(fileRef);
          };

          if (item.topView) {
            itemData.topView = await uploadFile(item.topView, `images/${uuidv4()}-topView`);
          }
          if (item.frontView) {
            itemData.frontView = await uploadFile(item.frontView, `images/${uuidv4()}-frontView`);
          }
          if (item.sideView) {
            itemData.sideView = await uploadFile(item.sideView, `images/${uuidv4()}-sideView`);
          }

          await setDoc(itemDocRef, itemData);
        }
      }

      setLoading(false);
      toast.success("General Menu added successfully");
      // Reset the form data after successful submission
      setFormData({
        restaurantName: "",
        uidreference: "",
        urlName: "",
      });
      setSections([{ name: "", originalName: "", sectionNumber: "", topNote: "", bottomNote: "", items: [{ name: "", price: "", itemRating: 0, itemRatingBy: 0, itemPriceRange: "", tasteCategory: "", itemType: "", nameExtra: "", descriptionExtra: "", positioninSection: "", description: "", foodAllergies: "", topView: null, frontView: null, sideView: null }] }]);
    
    } catch (error) {
      console.error("Error adding restaurant general menu:", error);
      setLoading(false);
      toast.error("Error adding restaurant general menu");
    }
  }

  if (loading) {
    return <Spinner />;
  }
  return (
    <Container fluid="true" className="mainpage">
      <Row>
        <Col>
          <h1 className="firstheading">Add General Menu</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="secondheading">Details of your Restaurant's Menu</h2>
        </Col>
      </Row>

      <Row>
        <Col md={2}></Col>
        <Col md={8} className="signupleftcolumn">
          <form onSubmit={handleSubmit}>
            {userData && (
              <p className="addproj-subheading">
                Restaurant Name: {userData.restName}
              </p>
            )}

            {sections.map((section, sectionIndex) => (
              <div key={sectionIndex} className="section">
                <div className="form-group">
                  <p className="signup-subheading">Section Name</p>
                  <input
                    type="text"
                    id={`section-${sectionIndex}-name`}
                    name="name"
                    className="email"
                    value={section.name}
                    onChange={(e) => handleSectionChange(sectionIndex, e)}
                    required
                  />
                </div>
                <div className="form-group">
                  <p className="signup-subheading">Section Number</p>
                  <input
                    type="number"
                    id={`section-${sectionIndex}-number`}
                    name="sectionNumber"
                    className="email"
                    value={section.sectionNumber}
                    onChange={(e) => handleSectionChange(sectionIndex, e)}
                    required
                  />
                </div>
                <div className="form-group">
                  <p className="signup-subheading">Top Note (if any)</p>
                  <textarea
                    id={`section-${sectionIndex}-number`}
                    name="topNote"
                    className="fieldtextarea"
                    value={section.topNote}
                    onChange={(e) => handleSectionChange(sectionIndex, e)}
                  />
                   
                </div>
                <div className="form-group">
                  <p className="signup-subheading">Bottom Note (if any)</p>
                  <textarea
                    id={`section-${sectionIndex}-number`}
                    name="bottomNote"
                    className="fieldtextarea"
                    value={section.bottomNote}
                    onChange={(e) => handleSectionChange(sectionIndex, e)}
                  />
                </div>
                {section.items.map((item, itemIndex) => (
                  <div key={itemIndex} className="item">
                    <div className="form-group">
                      <p className="signup-subheading">Item Name</p>
                      <input
                        type="text"
                        id={`item-${sectionIndex}-${itemIndex}-name`}
                        name="name"
                        className="email"
                        value={item.name}
                        onChange={(e) =>
                          handleItemChange(sectionIndex, itemIndex, e)
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Price</p>
                      <input
                        type="number"
                        id={`item-${sectionIndex}-${itemIndex}-price`}
                        name="price"
                        className="email"
                        value={item.price}
                        onChange={(e) =>
                          handleItemChange(sectionIndex, itemIndex, e)
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Item Price Range</p>
                      <input
                        type="text"
                        id={`item-${sectionIndex}-${itemIndex}-itemPriceRange`}
                        name="itemPriceRange"
                        className="email"
                        value={item.itemPriceRange}
                        onChange={(e) =>
                          handleItemChange(sectionIndex, itemIndex, e)
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Taste Category</p>
                      <input
                        type="text"
                        id={`item-${sectionIndex}-${itemIndex}-tasteCategory`}
                        name="tasteCategory"
                        className="email"
                        value={item.tasteCategory}
                        onChange={(e) =>
                          handleItemChange(sectionIndex, itemIndex, e)
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Item Type</p>
                      <input
                        type="text"
                        id={`item-${sectionIndex}-${itemIndex}-itemType`}
                        name="itemType"
                        className="email"
                        value={item.itemType}
                        onChange={(e) =>
                          handleItemChange(sectionIndex, itemIndex, e)
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Name Extras</p>
                      <textarea
                        id={`item-${sectionIndex}-${itemIndex}-nameExtra`}
                        name="nameExtra"
                        className="fieldtextarea"
                        value={item.nameExtra}
                        onChange={(e) =>
                          handleItemChange(sectionIndex, itemIndex, e)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Position In Section</p>
                      <input
                        type="number"
                        id={`item-${sectionIndex}-${itemIndex}-positioninSection`}
                        name="positioninSection"
                        className="email"
                        value={item.positioninSection}
                        onChange={(e) =>
                          handleItemChange(sectionIndex, itemIndex, e)
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Description</p>
                      <textarea
                        id={`item-${sectionIndex}-${itemIndex}-description`}
                        name="description"
                        className="fieldtextarea"
                        value={item.description}
                        onChange={(e) =>
                          handleItemChange(sectionIndex, itemIndex, e)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Description Extras</p>
                      <textarea
                        id={`item-${sectionIndex}-${itemIndex}-descriptionExtra`}
                        name="descriptionExtra"
                        className="fieldtextarea"
                        value={item.descriptionExtra}
                        onChange={(e) =>
                          handleItemChange(sectionIndex, itemIndex, e)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Food Allergies (If Any)</p>
                      <textarea
                        id={`item-${sectionIndex}-${itemIndex}-foodAllergies`}
                        name="foodAllergies"
                        className="fieldtextarea"
                        value={item.foodAllergies}
                        onChange={(e) =>
                          handleItemChange(sectionIndex, itemIndex, e)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Top View Image</p>
                      <input
                        type="file"
                        id={`item-${sectionIndex}-${itemIndex}-topView`}
                        name="topView"
                        className="email"
                        onChange={(e) => handleFileChange(sectionIndex, itemIndex, 'topView', e.target.files[0])}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Front View Image</p>
                      <input
                        type="file"
                        id={`item-${sectionIndex}-${itemIndex}-frontView`}
                        name="frontView"
                        className="email"
                        onChange={(e) => handleFileChange(sectionIndex, itemIndex, 'frontView', e.target.files[0])}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <p className="signup-subheading">Side View Image</p>
                      <input
                        type="file"
                        id={`item-${sectionIndex}-${itemIndex}-sideView`}
                        name="sideView"
                        className="email"
                        onChange={(e) => handleFileChange(sectionIndex, itemIndex, 'sideView', e.target.files[0])}
                        required
                      />
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="finalsubmit-button"
                  onClick={() => addItem(sectionIndex)}
                >
                  Add Item
                </button>
              </div>
            ))}
            <button
              type="button"
              className="finalsubmit-button"
              onClick={addSection}
            >
              Add Section
            </button>

            <button type="submit" className="finalsubmit-button">
              Submit
            </button>
          </form>

          <p>Note: Menu will be displayed publicly after approval of admin.</p>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
};

export default AddRGeneralMenu;
