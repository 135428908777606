import React from "react";
import { Link } from "react-router-dom";
// import signinprofessional from "../..//assets/images/signinprofessional.png";
// import signinstudent from "../..//assets/images/signinstudent.png";
// import signinvendor from "../..//assets/images/signinvendor.png";
import { Container, Row, Col } from 'react-bootstrap';

const SignUp = () => {
  return (
    <Container fluid>
      <Row className="signincontainer">
      <Col xs={12} className="signinboxcontainerone">
          <h1>Register</h1>
        </Col>
        <Col xs={12}>
        <Row>
        <div className="signinboxcontainer">
            <Col xs={12} sm={6} md={4} lg={4}>
         
                <Link to="customersignup" className="signinbox">
                  {/* <img src={signinstudent} alt="Student" /> */}
                  <span>Customer</span>
                </Link>
           
            </Col>
            <Col xs={12} sm={6} md={4} lg={4}>
           
                <Link to="restaurantsignup" className="signinbox">
                  {/* <img src={signinprofessional} alt="Professional" /> */}
                  <span>Restaurant</span>
                </Link>
           
            </Col>
              </div>
            </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;