import { useEffect, useState, useCallback } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoImage from './../assets/images/logo.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";

function NavScrollExample() {

  const [pageState, setPageState] = useState("Log in");
  const [isUser, setIsUser] = useState(null);
  const [userType, setUserType] = useState("null");

  const auth = getAuth();

    
  const getUserType = useCallback(async () => {
    try {
      if (auth.currentUser) {
        const userDocRef = doc(db, "usersrestaurants", auth.currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          return userData.usertype
          || "default"; // Set a default value if 'usertype' is not present in the document
        } else {
          return "default"; // Set a default value if the user document does not exist
        }
      } else {
        return "default"; // Set a default value if the currentUser is not available
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return "default"; // Set a default value in case of any error
    }
  }, [auth.currentUser]);


    // Move the fetchUserType into a separate useEffect hook
    useEffect(() => {
      async function fetchUserData() {
        try {
          const userType = await getUserType();
          setUserType(userType || "default"); // Set the userType directly
        } catch (error) {
          console.error("Error fetching user data:", error);
          setUserType("default"); // Set a default value in case of any error
        }
      } 
   
        // Fetch the user data only if the user is logged in and userType is not set yet
        fetchUserData();


    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setPageState("Profile");
        setIsUser(true);
      } else {
        setPageState("Log in");
        setIsUser(false);
      }
    });
    return () => {
      // Unsubscribe the auth state listener when the component unmounts
      unsubscribe();
    };
  }, [auth,isUser, userType, getUserType]);



  function onLogout() {
    auth.signOut();
  }


  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
        <img src={logoImage} className="logo-img" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="me-auto my-2 my-lg-0 navbarbuttons"            
            navbarScroll
            defaultActiveKey="/"
           
          >
            <Nav.Link as={Link} to="search">Search</Nav.Link>
            <Nav.Link as={Link} to="reviews">Reviews</Nav.Link>
            <Nav.Link as={Link} to="menus">Menus</Nav.Link>
    
        
          </Nav>

   

          {/* Conditionally render the Navbar links based on whether userType is set or not */}
          {userType === null ? (
            // Show a loading state or fallback UI while user data is being fetched
            // For example, you can show a spinner or a message like "Loading..."
            <div>Loading...</div>
          ) : isUser ? (

            (userType === "restaurant") ? ( 
              (

<NavDropdown
title={pageState}
id="navbarScrollingDropdown"
className="mx-2 my-2 my-lg-0"
>
{/* Render dropdown links based on userType */}
<NavDropdown.Item as={Link} to={`profile/${userType}`}>
  Profile
</NavDropdown.Item>


<NavDropdown.Item as={Link} to={`add${userType}generalmenu`}>
  Add General Menu
</NavDropdown.Item>

{/* <NavDropdown.Item as={Link} to={`add${userType}catalogue`}>
  Add New Catalogue
</NavDropdown.Item> */}

{/* <NavDropdown.Item as={Link} to={`add${userType}video`}>
  Add New Video
</NavDropdown.Item> */}

<NavDropdown.Item as={Link} to={`editprofile-${userType}`}>
  Edit Profile
</NavDropdown.Item>

<NavDropdown.Item  
onClick={onLogout}
as={Link} to="/"              
>      
  Log Out
</NavDropdown.Item>
</NavDropdown>
)
             ) : (

              <NavDropdown
              title={pageState}
              id="navbarScrollingDropdown"
              className="mx-2 my-2 my-lg-0 righlinks"
            >
              {/* Render dropdown links based on userType */}
              <NavDropdown.Item as={Link} to={`${userType}profile`}>
                Profile
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`editprofile-${userType}`}>
                Edit Profile
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`addproject-${userType}`}>
                Add Project
              </NavDropdown.Item>
              <NavDropdown.Item  
              onClick={onLogout}
              as={Link} to="/"              
              >      
                Log Out
              </NavDropdown.Item>
            </NavDropdown>
            )
            )   


          :
          (
            <Nav.Link className="mx-2 my-2 my-lg-0 righlinks" 
          as={Link}
          to="login"
          >
          {pageState}
            </Nav.Link>
          )}            
          <Nav.Link className="mx-2 my-2 my-lg-0 righlinks"
             as={Link}
              to="signup">
            Sign Up
            </Nav.Link>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;