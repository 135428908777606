import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import Card from "react-bootstrap/Card";
import TextLimit from "../../components/TextLimit";
import nocovericon from "../../assets/images/nocovericon.png";
import nodpicon from "../../assets/images/nodpicon.png";
import productsImg from "../../assets/images/vendorproducts.png";
import cataloguesImg from "../../assets/images/vendorcatalogues.png";
import videosImg from "../../assets/images/vendorvideos.png";
import bestsellersImg from "../../assets/images/vendorbestsellers.png";
import {HiPhone} from "react-icons/hi";
import { IoMdPin } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { Link } from "react-router-dom";

function RProfilePage() {
    const params = useParams();
    const restaurantName = params.restaurantName;

    const [loading, setLoading] = useState(true);

    const [restaurantData, setRestaurantData] = useState(null);
    const [restaurantDataTwo, setRestaurantDataTwo] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Reference to the document in Firestore
                const restaurantDocRef = doc(db, "restaurants", "doccontainingallRestaurants", restaurantName, "generalMenu");
                
                // Fetch the document
                const docSnapshot = await getDoc(restaurantDocRef);

                // Check if the document exists
                if (docSnapshot.exists()) {
                    // Extract data from the document
                    const data = docSnapshot.data();
                    setRestaurantData(data);

                } else {
                    console.error(`Document for restaurant '${restaurantName}' not found`);
                    // Handle case when the document doesn't exist
                }
            
                const docRef = doc(db, "usersrestaurants", restaurantData?.uidreference);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setRestaurantDataTwo(docSnap.data());
                }    
            } catch (error) {
                console.error("Error fetching restaurant data:", error);
                // Handle error fetching data
            }
        };
        
        setLoading(false);
        fetchData();
    }, [restaurantName, restaurantData?.uidreference, restaurantData]); // Execute the effect whenever the restaurantName changes

    const urlName = params.restaurantName;

    if (loading) {
        return <Spinner />;
      }
    

    return (
        <Container fluid="true" className="mainpage">
            <Row>
                <Col>
                {restaurantData ? (
                   
                            <h1 className="firstheading">{restaurantData.restaurantName}</h1>
                        
                    
                    ) : (
                        <p>Loading restaurant data...</p>
                    )}
                    
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className="secondheading">Profile Page</h2>
                </Col>
            </Row>
      

            <Row>
    <Row>
      
      <Col md={12} xs={12} sm={12} className="vendorprofiletopright">
          <Row>
            <div className="vendorprofilecontainer">
              {restaurantDataTwo && (
                <div>
                      {restaurantDataTwo.profileImageUrl ?  (<img className="invdlprofile-image-invdlcompanypage"
    src={restaurantDataTwo.profileImageUrl} 
     alt="Logo" 
      />) : ( 
        
        <img
                  src={nodpicon}
                  alt="No Logo"
                  className="invdlprofile-image-invdlcompanypage"
                />
       ) }
                </div>
              )}
            </div>
          </Row>

          <Row>
            {restaurantDataTwo && (
              <div>
                <h2 className="vendorprofile-heading">Type: {restaurantDataTwo.restType}</h2>
              </div>
            )}
          </Row>

          <Row>

          {restaurantDataTwo && (
              <div>
                <TextLimit className="vendorprofiledescription" text={restaurantDataTwo.about} limit={30} /> 
              </div>
            )}
          </Row>

          
          <Row>
            {restaurantDataTwo && (
              <div>
                <p className="vendorprofiledescription"><MdEmail/> {restaurantDataTwo.restWebsite}</p>
              </div>
            )}
          </Row>

          <Row>
            {restaurantDataTwo && (
              <div>
                <p className="vendorprofiledescription"><HiPhone/> {restaurantDataTwo.contact}</p>
              </div>
            )}
          </Row>

          <Row>
            {restaurantDataTwo && (
              <div>
                <p className="vendorprofiledescription"><IoMdPin/> {restaurantDataTwo.country}</p>
              </div>
            )}
          </Row>
          <Row>
                 
       
          </Row>

          </Col>
      </Row>

      <Row className="vendorlinkcards">

      <Col md={3}>
        <Card className="vendorlcard">
          <Card.Img
            variant="top"
            loading="lazy"
            className="vendorlcardimg"
            src={productsImg}
            alt="MENU"
          />
          <div className="vlcard-text-overlay">
            <Card.Body>
              <Link to={`/r/${urlName}/menu`} className="vlcustom-link">
                <Card.Title>MENU</Card.Title>
              </Link>
            </Card.Body>
          </div>
        </Card>
        </Col>



        <Col md={3}>

      

        <Card className="vendorlcard">
          <Card.Img
            variant="top"
            loading="lazy"
            className="vendorlcardimg"
            src={cataloguesImg}
            alt="BRANCHES"
          />
          <div className="vlcard-text-overlay">
            <Card.Body>
              <Link to={`/r/${urlName}/branches`} className="vlcustom-link">
                <Card.Title>BRANCHES</Card.Title>
              </Link>
            </Card.Body>
          </div>
        </Card>

        </Col>

        <Col md={3}>

    

        <Card className="vendorlcard">
          <Card.Img
            variant="top"
            loading="lazy"
            className="vendorlcardimg"
            src={videosImg}
            alt="VIDEOS"
          />
          <div className="vlcard-text-overlay">
            <Card.Body>
              <Link to={`/vendorvideos/${urlName}`} className="vlcustom-link">
                <Card.Title>ABC</Card.Title>
              </Link>
            </Card.Body>
          </div>
        </Card>

        </Col>

        <Col md={3}>


       

        <Card className="vendorlcard">
          <Card.Img
            variant="top"
            loading="lazy"
            className="vendorlcardimg"
            src={bestsellersImg}
            alt="BEST SELLERS"
          />
          <div className="vlcard-text-overlay">
            <Card.Body>
              <Link to={`/vendorbestsellers/${urlName}`} className="vlcustom-link">
                <Card.Title>XYZ</Card.Title>
              </Link>
            </Card.Body>
          </div>
        </Card>

        </Col>


</Row>
      
    </Row>
        </Container>
    );
}

export default RProfilePage;
