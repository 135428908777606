import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";
import Spinner from "../Spinner";
import Container from 'react-bootstrap/Container';
import { Row, Col } from "react-bootstrap";

const RBranchPage = () => {
  const { restaurantName, branchName } = useParams();
  const [loading, setLoading] = useState(true);
  const [branchData, setBranchData] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        const branchDocRef = doc(db, "restaurants", "doccontainingallRestaurants", restaurantName, branchName);
        const branchDocSnapshot = await getDoc(branchDocRef);

        if (branchDocSnapshot.exists()) {
          setBranchData(branchDocSnapshot.data());
        }

        /////////////////////////////
        const branchMenuCollectionRef = doc(branchDocRef, "branchMenu", "generalBmenu");
        const branchMenuSnapshot = await getDoc(branchMenuCollectionRef);

        if (branchMenuSnapshot.exists()) {
            const sections = branchMenuSnapshot.data().sections || [];

             // Sort sections by sectionNumber
            sections.sort((a, b) => a.sectionNumber - b.sectionNumber);


            const menuItemsData = [];
  
            for (const section of sections) {
              const sectionName = section.name; // Ensure sectionName is a string
              const sectionOriginalName = section.originalName;
              const topNote = section.topNote;
              const bottomNote = section.bottomNote;
              const sectionItemsSnapshot = await getDocs(collection(branchMenuCollectionRef, sectionName));
              const items = sectionItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          
              menuItemsData.push({ sectionName, sectionOriginalName, topNote, bottomNote, items });
            }
  
            setMenuItems(menuItemsData);
          }

      } catch (error) {
        console.error("Error fetching branch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBranchData();
  }, [restaurantName, branchName]);

  if (loading) {
    return <Spinner />;
  }

  if (!branchData) {
    return (
      <Container fluid="true" className="mainpage">
        <Row>
          <Col>
            <h1 className="firstheading">Branch Not Found</h1>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid="true" className="mainpage">
      <Row>
        <Col>
        
          <h1 className="firstheading">{branchData.branchName || "Unable to fetch branch data."}</h1>
          <h2 className="vendorprofile-heading">{branchData.restaurantName}</h2>
          <img className="invdlprofile-image-invdlcompanypage" src={branchData.branchQRimg} alt="Branch QR" />
        </Col>
      </Row>
      {menuItems.length > 0 ? (
        menuItems.map((section, sectionIndex) => (
          <Row key={sectionIndex} className="menu-section">
            <Col md={12}>
              <h2 className="section-title">{section.sectionOriginalName}</h2>
              {section.topNote && (
                <p className="section-note top-note">{section.topNote}</p>
              )}
              {section.items.map((item, itemIndex) => (
                <div key={itemIndex} className="menu-item">
                  <h3 className="item-name">
                    <Link className="item-name-link" to={`/r/${restaurantName}/branch/${branchData.branchUrl}/menu/${section.sectionName}/${item.id}`}>{item.name}</Link>
                  </h3>
                  <p className="item-price">{item.nameExtra}</p>
                  <p className="item-price">Price: ${item.price}</p>
                  <p className="item-description">{item.description}</p>
                  <p className="item-description">{item.descriptionExtra}</p>
                  <p className="item-price">Rating: {item.itemRating} ({item.itemRatingBy})</p>
                  <p className="item-price">Taste Category: {item.tasteCategory}</p>
                  <p className="item-price">Price Range: {item.itemPriceRange}</p>
                  <p className="item-price">Type: {item.itemType}</p>
                  <p className="item-price">Food Allergies (If Any): {item.foodAllergies}</p>
                </div>
              ))}
              {section.bottomNote && (
                <p className="section-note bottom-note">{section.bottomNote}</p>
              )}
            </Col>
          </Row>
        ))
      ) : (
        <Row>
          <Col>
            <p>No menu data available.</p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default RBranchPage;