import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sidebar from './Sidebar';
import { getAuth, signOut } from "firebase/auth";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from "react-icons/fa"; // Import a signout icon if needed.
import AdminHomepage from './AdminHomepage';
import AdminAddRestaurant from './AdminAddRestaurant';
import "../../styles/styles.css"
import './AdminPanel.css';

function AdminPanel() {

    const [selectedPage, setSelectedPage] = useState('admin-home'); // Initially selected page
    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setSelectedPage(page);
      };

      const handleSignOut = async () => {
        try {
          const auth = getAuth();
          await signOut(auth);
          // Redirect to the sign-in page or perform any other action after signing out.
          navigate("/admin"); // Make sure to import 'navigate' from 'react-router-dom'.
        } catch (error) {
          console.error("Error signing out:", error);
        }
      };
      

    return (
        <Container fluid="true" className="mainpage">
        <Row>
            <Col>
                <h1 className="firstheading">Admin Panel</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <h2 className="secondheading">Welcome Admin</h2>
            </Col>
        </Row>


                {/* <Row>
                    <Col>
                        <Button onClick={() => navigate('/searchrestaurant')} variant="outline-dark">Search for Restaurants</Button>
                        <Button onClick={() => navigate('/searchfooditem')} variant="outline-dark">Search for Food Item</Button>
                    </Col>
                </Row> */}


                <Row>
          <Col md={2} className="sidebar">
            <Sidebar onSelectPage={handlePageChange} selectedPage={selectedPage} />
          </Col>
          <Col md={10} className="admincontent">
             {/* Add the signout button at the top right */}
             <div className="adminsignout-button">
            <button className="signin-button"  onClick={handleSignOut}>
              <FaSignOutAlt /> Sign Out
            </button>
          </div>
            {/* Display different pages based on selectedPage */}
            {selectedPage === 'admin-home' && <AdminHomepage />}
            {selectedPage === 'addrestaurant' && <AdminAddRestaurant />}
        
            
          </Col>
        </Row>
      
 

    </Container>

    )

}

export default AdminPanel;






