import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { Row, Col } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { db } from "../../config/firebase";
import {
  serverTimestamp,
  setDoc,
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import Select from "react-select";
import qrcode from 'qrcode';

const RestaurantSignup = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedOthertypes, setSelectedOthertypes] = useState([]);
  const [newOthertype, setNewOthertype] = useState(""); 


  const [selectedRestype, setSelectedRestype] = useState(null); // Change to null for initial state

  const [sociallinksInput, setSociallinksInput] = useState("");
  const [sociallinksArray, setSociallinksArray] = useState([]);

  const [countryOptions] = useState([
    { value: "Pakistan", label: "Pakistan" },
    { value: "International", label: "International" },
  ]);



  const allOtherTypes = [
    { value: "Fast", label: "Fast" },
    { value: "Chinese", label: "Chinese" },
    { value: "Italian", label: "Italian" },
    { value: "Desi", label: "Desi" },
    { value: "Others", label: "Others" },  
  ];


  const restypeOptions = [
    { value: "Fast", label: "Fast" },
    { value: "Chinese", label: "Chinese" },
    { value: "Italian", label: "Italian" },
    { value: "Desi", label: "Desi" },
  ];

  const [formData, setFormData] = useState({
    usertype: "restaurant",
    isactive: false,
    chargespaid: "no",
    restName: "",
    urlName: "",
    restType: {},
    restOthertypes: {},
    country: "",
    username: "",
    email: "",
    password: "",
    address: "",
    contact: "",
    contacttwo: "",
    about: "",
    restRating: "",
    restRatingoutof: "",
    restInsta: "",
    restFb: "",
    restX: "",
    restWhatsapp: "",
    restWebsite: "",
    sociallinks: {},
    restPriceavg: "",
    restQRimg: "",
    restQRcode: "",
    restNoofReviews: "",
    restNoofBranches: "",
    restCities: {},  

  });

  const {
    usertype,
    isactive,
    chargespaid,
    restName,
    urlName,
    restType,
    restOthertypes,
    country,
    username,
    email,
    password,
    address,
    contact,
    contacttwo,
    about,
    restRating,
    restRatingoutof,
    restInsta,
    restFb,
    restX,
    restWhatsapp,
    restWebsite,
    sociallinks,
    restPriceavg,
    restQRimg,
    restQRcode,
    restNoofReviews,
    restNoofBranches,
    restCities,

  } = formData;

  function handleAddOthertypes(option) {
    // Check if the selected Other Type already exists in the list
    const exists = selectedOthertypes.find(
      (othertype) => othertype.value === option.value
    );
    // If the selected Other Type does not exist, add it to the list
    if (!exists) {
      setSelectedOthertypes((prevSelectedOthertypes) => [
        ...prevSelectedOthertypes,
        option,
      ]);
    }
  }



  const handleAddSociallinks = () => {
    if (sociallinksInput.trim() !== "" && sociallinksArray.length < 6) {
      setSociallinksArray((prevSociallinks) => [
        ...prevSociallinks,
        sociallinksInput.trim(),
      ]);
      setSociallinksInput("");
    }
  };



  function onChange(e) {
    let boolean = null;
    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }

    // Handle changes for the profile image input
    if (e.target.id === "profileImage") {
      setProfileImage(e.target.files[0]);
    }

    // Handle changes for the cover image input
    if (e.target.id === "coverImage") {
      setCoverImage(e.target.files[0]);
    }

    // Text/Boolean/Number
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }

    // Handle changes for the Other Type input field
    if (e.target.id === "othertype") {
      setNewOthertype(e.target.value);
    }

    if (e.target.id === "restType") {
      setSelectedRestype(e.target.value);
    }
  }

  function handleSelectChange(selectedOption, id) {
    setFormData((prevState) => ({
      ...prevState,
      [id]: selectedOption.value, // Update the state with the selected value
    }));
  }

  const handleDeleteSociallinks = (index) => {
    setSociallinksArray((prevSociallinks) => {
      const updatedSociallinks = [...prevSociallinks];
      updatedSociallinks.splice(index, 1);
      return updatedSociallinks;
    });
  };



  async function checkIfRestaurantExists(urlName) {
    const collectionRef = doc(db, "restaurants", "doccontainingallRestaurants", urlName, "generalMenu"); // Correct the collection name
    const docSnap = await getDoc(collectionRef);
    return docSnap.exists();
  };


  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);


    const constructedUrlName = restName.toLowerCase().replace(/\s+/g, '');
    const restaurantExists = await checkIfRestaurantExists(constructedUrlName);

    if (restaurantExists) {
      setLoading(false);
      toast.warn("Restaurant name already exists. Try a different name.");
      return;
    }

    if (newOthertype.trim() !== "") {
      handleAddOthertypes({
        value: restOthertypes.trim(),
        label: restOthertypes.trim(),
      });
      setNewOthertype("");
    }


    // Get the selected Other Type values and store them in an array
    const othertypeValues = selectedOthertypes.map(
      (othertype) => othertype.value
    );

    // Get the selected rest type value and store it
    const restypeValue = selectedRestype?.value ?? "";

    let profileImageUrl = null;
    if (profileImage) {
      const storage = getStorage();
      const profileImageRef = ref(
        storage,
        `restaurantLogos/primaryLogoPics/${uuidv4()}`
      );
      const uploadProfileTask = uploadBytesResumable(
        profileImageRef,
        profileImage
      );

      await uploadProfileTask;

      profileImageUrl = await getDownloadURL(profileImageRef);
    }

    // Upload cover image if selected
    let coverImageUrl = null;
    if (coverImage) {
      const storage = getStorage();
      const coverImageRef = ref(
        storage,
        `restaurantLogos/otherLogoPics/${uuidv4()}`
      );
      const uploadCoverTask = uploadBytesResumable(coverImageRef, coverImage);

      await uploadCoverTask;

      coverImageUrl = await getDownloadURL(coverImageRef);
    }

    try {
      const auth = getAuth();

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      updateProfile(auth.currentUser, {
        displayName: username,
      });

      const user = userCredential.user;

      console.log(user);

      const formDataCopy = {
        ...formData,
        restType: restypeValue,
        urlName: restName.toLowerCase().replace(/\s+/g, ''), 
        profileImageUrl,
        coverImageUrl,
        restRating: 0,
        restRatingoutof: 5,
        restPriceavg: 0,
        sociallinks: sociallinksArray,
        restQRimg,
        restQRcode,
        restNoofBranches: 0,
        restNoofReviews: 0,
        restCities: [],
        restOthertypes: othertypeValues,
      };

      delete formDataCopy.password;

      formDataCopy.timestamp = serverTimestamp();

      formDataCopy.restQRcode = "https://www.menusbyv.com/r/" + formDataCopy.urlName;
      formDataCopy.restQRimg = await qrcode.toDataURL(formDataCopy.restQRcode);

      await setDoc(doc(db, "usersrestaurants", user.uid), formDataCopy);

    // Create an empty generalMenu document in the new restaurant's subcollection
    await setDoc(doc(db, "restaurants", "doccontainingallRestaurants", constructedUrlName, "generalMenu"), {});

      toast.success("Sign Up was successful");
      navigate("/");
    } catch (error) {
      toast.error("Something went wrong. Please contact MenusbyV support.");
      console.error(error);
      setLoading(false);
    }
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Row>
      <div className="signupcontainer">
        <Col md={6}>
          <h3 className="sign-up-header">Create Your Account</h3>

          <form onSubmit={onSubmit}>
            <Row>
              <Col md={3} className="signupleftcolumn">
                <p className="signup-subheading">
                  Restaurant Name
                </p>

                <div>
                  <input
                    className="email"
                    type="text"
                    id="restName"
                    placeholder="Restaurant Name"
                    required={true}
                    value={restName}
                    onChange={onChange}
                  />
                </div>

                <p className="signup-subheading">Restaurant Type</p>
                <div>
                  <Select
                    id="restType"
                    value={selectedRestype}
                    options={restypeOptions}
                    onChange={(selectedOption) =>
                      setSelectedRestype(selectedOption)
                    }
                    placeholder="Select Restaurant Type"
                    isClearable={true}
                    required
                  />
                </div>

                
                <p className="signup-subheading">Restaurant Logo</p>
                <div className="imagesinput">
                  <input
                    type="file"
                    id="profileImage"
                    accept="image/*"
                    // accept=".jpg,.png,.jpeg"
                    onChange={onChange}
                  />
                </div>

                {/* Cover Image */}
                <p className="signup-subheading">Any Other Logo</p>
                <div className="imagesinput">
                  <input
                    type="file"
                    id="coverImage"
                    accept="image/*"
                    // accept=".jpg,.png,.jpeg"
                    onChange={onChange}
                  />
                </div>


                <p className="signup-subheading">Other Types (If Any)</p>
                <div>
                  <Select
                    id="restOthertypes"
                    isMulti
                    options={[
                      ...allOtherTypes,
                      { value: "restOthertypes", label: "Add Another Type" },
                    ]}
                    value={selectedOthertypes}
                    onChange={(selectedOptions) => {
                      // Update the selected Other types and add new Other type if needed
                      setSelectedOthertypes(selectedOptions);
                      if (
                        selectedOptions.length > 0 &&
                        selectedOptions[selectedOptions.length - 1].value ===
                          "restOthertypes"
                      ) {
                        handleAddOthertypes(
                          selectedOptions[selectedOptions.length - 1]
                        );
                      }
                    }}
                  />
                </div>

       

                <Row>
                  <Col>
                    <div className="react-select-field">
                      <p className="addproj-subheading">Country</p>
                      <Select
                        options={countryOptions}
                        id="country"
                        value={countryOptions.find(
                          (option) => option.value === country
                        )} // Find the matching option for the current value
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, "country")
                        }
                      />
                    </div>
                  </Col>

                </Row>
              </Col>

              <Col md={3} className="signuprightcolumn">
                <p className="signup-subheading">About The Restaurant</p>
                <textarea
                  type="text"
                  id="about"
                  value={about}
                  onChange={onChange}
                  placeholder="Description / Punch Line etc"
                  required
                  className="fieldtextarea"
                />

                <p className="signup-subheading">Mobile Helpline</p>

                <div>
                  <input
                    className="email"
                    type="text"
                    id="contact"
                    placeholder="Mobile Number"
                    required={false}
                    value={contact}
                    onChange={onChange}
                  />
                </div>

                <p className="signup-subheading">Landline Helpline</p>

<div>
  <input
    className="email"
    type="text"
    id="contacttwo"
    placeholder="Landline Number"
    required={false}
    value={contacttwo}
    onChange={onChange}
  />
</div>

<p className="signup-subheading">Whatsapp Number</p>
            <input
            className="email"
              type="text"
              id="restWhatsapp"
              value={restWhatsapp}
              onChange={onChange}
              placeholder="Whatsapp Number"
              required={false}
              
            />
            <p className="signup-subheading">Instagram</p>
            <input
            className="email"
              type="text"
              id="restInsta"
              value={restInsta}
              onChange={onChange}
              placeholder="Instagram"
              required={false}
              

            />
            <p className="signup-subheading">Facebook</p>
            <input
            className="email"
              type="text"
              id="restFb"
              value={restFb}
              onChange={onChange}
              placeholder="Facebook"
              required={false}
              
            />
            <p className="signup-subheading">X (Twitter)</p>
            <input
            className="email"
              type="text"
              id="restX"
              value={restX}
              onChange={onChange}
              placeholder="X (Twitter)"
              required={false}
              
            />

<p className="signup-subheading">Restaurant Website</p>
            <input
             className="email"
              type="text"
              id="restWebsite"
              value={restWebsite}
              onChange={onChange}
              placeholder="Website URL"
              required={false}
            />


                <p className="addproj-subheading">Any Other Social Links</p>
                <div className="hashtag-container">
                  {sociallinksArray.map((sociallink, index) => (
                    <div key={index} className="hashtag-item">
                      <span className="hashtag">{sociallink}</span>
                      <button
                        type="button"
                        onClick={() => handleDeleteSociallinks(index)}
                        className="hashtag-delete-button"
                      >
                        <RxCross2 />
                      </button>
                    </div>
                  ))}
                </div>
                <div className="hashtag-input-container">
                  <input
                    type="text"
                    id="sociallinks"
                    value={sociallinksInput}
                    onChange={(e) => setSociallinksInput(e.target.value)}
                    placeholder="Add a Social Link"
                    maxLength="100"
                    minLength="1"
                    className="fieldtextarea"
                  />
                  <button
                    type="button"
                    onClick={handleAddSociallinks}
                    className="sociallinks-add-button"
                    disabled={
                      sociallinksArray.length >= 6 ||
                      sociallinksInput.trim() === ""
                    }
                  >
                    Add
                  </button>
                </div>

                <p className="signup-subheading">Head Office Address</p>

                <div>
                  <input
                    className="email"
                    type="text"
                    id="address"
                    placeholder="Head Office Address"
                    value={address}
                    onChange={onChange}
                  />
                </div>

                <Row>
                  <Col>
                    <p className="signup-subheading">Username</p>

                    <div>
                      <input
                        className="email"
                        type="text"
                        id="username"
                        placeholder="This won't be public"
                        required={true}
                        value={username}
                        onChange={onChange}
                      />
                    </div>
                  </Col>
                  <Col>
                    <p className="signup-subheading">Email</p>

                    <div>
                      <input
                        className="email"
                        type="email"
                        id="email"
                        placeholder="Email Address"
                        required={true}
                        value={email}
                        onChange={onChange}
                      />
                    </div>
                  </Col>
                </Row>

                <p className="signup-subheading">Password</p>
                <div className="pw-section">
                  <input
                    className="email"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    required={true}
                    value={password}
                    onChange={onChange}
                  />
                  {showPassword ? (
                    <AiFillEyeInvisible
                      onClick={() => setShowPassword((prev) => !prev)}
                      className="eye-icon"
                    />
                  ) : (
                    <AiFillEye
                      onClick={() => setShowPassword((prev) => !prev)}
                      className="eye-icon"
                    />
                  )}
                </div>
                <div className="signin-section">
                  <p>
                    Have an account?
                    <Link to="/sign-in" className="signin ">
                      Sign In
                    </Link>
                  </p>
                  <p className="email-section">
                    <Link to="/forgotpassword" className="forgot-pw">
                      Forgot Password?
                    </Link>
                  </p>
                </div>
                <div className="buttoncontainer">
                  <button className="signin-button" type="submit">
                    SIGN UP
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </Col>
      </div>
    </Row>
  );
};

export default RestaurantSignup;
