import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { Row, Col } from "react-bootstrap";
import logoImage from "../../assets/images/logo.png";
import { toast } from "react-toastify";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase"; // Make sure to import your Firestore instance


const RLogin = () => {

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  function onChange(e) {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  }

  

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (userCredential.user) {
        const user = userCredential.user;
        
        // Check if the signed-in user is a professional
        const userDocRef = doc(db, "usersrestaurants", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
            navigate("/");
          } else {
            const auth = getAuth();
            await auth.signOut();
            toast.error("Sorry, no data found for this user. Please sign up.");
          }
        
      }
    } catch (error) {
      toast.error("Bad user credentials");
    }
  };

  
  return (
    <Row>     
      <div className="signinupcontainer">

   
      <Col md={3} className="logoimgcontainer">
      <img src={logoImage} 
      alt="logo"
      className="registerlogoimg"
      />


      </Col>

      <Col md={3} className="formcontainer">
   
          <form onSubmit={onSubmit}>
            <div className="text-section">
              <input
                className="email"
                type="email"
                id="email"
                placeholder="Email Address"
                required={true}
                value={email}
                onChange={onChange}
              />
            </div>
            <div className="pw-section">
              <input
                className="email"
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Password"
                required={true}
                value={password}
                onChange={onChange}
              />
              {showPassword ? (
                <AiFillEyeInvisible
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="eye-icon"
                />
              ) : (
                <AiFillEye
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="eye-icon"
                />
              )}
            </div>
            <div className="signin-section">
              <p className="text-section">
                Don't have an account?
                <Link
                  to="/sign-up"
                  className="register-button"
                >
                  Register
                </Link>
              </p>
              <p className="text-section">
                <Link
                  to="/forgot-password"
                  className="forgotpw-button"
                >
                  Forgot Password?
                </Link>
              </p>
            </div>
            <button
              className="signin-button"
              type="submit"
            >
              LOGIN
            </button>
         
          </form>



      </Col>

    
        




      </div>
    </Row>
  );
};

export default RLogin;
