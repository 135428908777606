import React from "react";
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import AppBody from "./components/AppBody";
import MainPage from "./components/Mainpage";
import AdminPanel from "./components/AdminPanel/AdminPanel";

////////////// Authentication Pages////////////////
import PrivateRoute from "./components/PrivateRoute";
import SignUp from "./components/SignupSignin/SignUp";
import LogIn from "./components/SignupSignin/LogIn";
import RLogin from "./components/SignupSignin/RLogin";
import RSignup from "./components/SignupSignin/RSignup";
import ForgotPassword from "./components/SignupSignin/ForgotPassword";

/////////////Private Profile Pages/////////////
import RProfile from "./components/PrivateProfiles/RProfile/RProfile";
import RProfileMenu from "./components/PrivateProfiles/RProfile/RProfileMenu";
import RProfileItem from "./components/PrivateProfiles/RProfile/RProfileItem";
import RProfileBranches from "./components/PrivateProfiles/RProfile/RProfileBranches";
import RProfileBranchPage from "./components/PrivateProfiles/RProfile/RProfileBranchPage";
import RBranchItem from "./components/PrivateProfiles/RProfile/RBranchItem";

import AddRGeneralMenu from "./components/PrivateProfiles/AddRitems/AddRGeneralMenu"; 
import AddRBranch from "./components/PrivateProfiles/AddRitems/AddRBranch"; 

//////// Invdl Profile Pages/////////////
import RProfilePage from "./components/InvdlProfilePages/RProfilePage";
import RGeneralMenuPage from "./components/InvdlProfilePages/RGeneralMenuPage";
import RGeneralMenuItem from "./components/InvdlProfilePages/RGeneralMenuItem";
import RBranchesPage from "./components/InvdlProfilePages/RBranchesPage";
import RBranchPage from "./components/InvdlProfilePages/RBranchPage";
import RBranchItemPage from "./components/InvdlProfilePages/RBranchItemPage";

////////////////// Main Pages /////////////////////////
import MenusPage from "./components/MenusPage/MenusPage";



const router = createBrowserRouter([
    {
      path: "/",
      element: <AppBody />,
      errorElement: <ErrorPage />,
      children: [ {
        path: "/",
        element: <MainPage />,
      },          
      {
        path: "menus",
        element: <MenusPage />,
      },
      {
        path: "admin",
        element: <AdminPanel />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },  
      {
        path: "login",
        element: <LogIn />,
      },  
      {
        path: "login/restaurantlogin",
        element: <RLogin />,
      },  
      {
        path: "signup/restaurantsignup",
        element: <RSignup />,
      
      },
      {
        path: "forgotpassword",
        element: <ForgotPassword />,
      
      },
      {
        path: "profile/restaurant",
        element: <PrivateRoute />,
        children: [ {
          path: "",
          element: <RProfile />,
        },
        {
          path: "addgeneralmenu",
          element: <AddRGeneralMenu />,
        },
        {
          path: "addbranch",
          element: <AddRBranch />,
        },
        {
          path: "mymenu",
          element: <RProfileMenu />,
        },
        {
          path: "mymenu/:sectionName/:itemId",
          element: <RProfileItem />,
        },
        {
          path: "mybranches",
          element: <RProfileBranches />,
        },
        {
          path: "mybranches/:branchName",
          element: <RProfileBranchPage />,
        },
        {
          path: "mybranches/:branchName/menu/:sectionName/:itemId",
          element: <RBranchItem />,
        },
        
      
      ]
      },
      {
        path: "/r/:restaurantName",
        element: <RProfilePage />,
      }, 
      {
        path: "/r/:restaurantName/menu",
        element: <RGeneralMenuPage />,
      }, 
      {
        path: "/r/:restaurantName/menu/:sectionName/:itemId",
        element: <RGeneralMenuItem />,
      }, 
      {
        path: "/r/:restaurantName/branches",
        element: <RBranchesPage />,
      }, 
      {
        path: "/r/:restaurantName/branch/:branchName",
        element: <RBranchPage />,
      }, 
      {
        path: "/r/:restaurantName/branch/:branchName/menu/:sectionName/:itemId",
        element: <RBranchItemPage />,
      }, 
      
  ]
  },

        ]);


function App(){
return <RouterProvider router={router} />  
};

export default App;