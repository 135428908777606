import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";
import Spinner from "../Spinner";
import Container from 'react-bootstrap/Container';
import { Row, Col } from "react-bootstrap";

const RBranchesPage = () => {
  const { restaurantName } = useParams();
  const [loading, setLoading] = useState(true);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const branchesCollectionRef = collection(db, "restaurants", "doccontainingallRestaurants", restaurantName);
        const branchesSnapshot = await getDocs(branchesCollectionRef);

        const branchesData = branchesSnapshot.docs
          .filter(doc => doc.id !== "generalMenu")
          .map(doc => ({ id: doc.id, ...doc.data() }));

        setBranches(branchesData);
      } catch (error) {
        console.error("Error fetching branches data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBranches();
  }, [restaurantName]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container fluid="true" className="mainpage">
      <Row>
        <Col>
          <h1 className="firstheading">Restaurant Branches</h1>
        </Col>
      </Row>
      {branches.length > 0 ? (
        branches.map((branch, index) => (
          <Row key={index} className="branch-section">
            <Col md={12}>
              <h2 className="branch-title">
                <Link className="item-name-link" to={`/r/${restaurantName}/branch/${branch.branchUrl}`}>{branch.branchName || "Unable to fetch branch data."}</Link>
              </h2>
              <p className="branch-address">{branch.address}</p>
              <p className="branch-contact">{branch.contact}</p>
            </Col>
          </Row>
        ))
      ) : (
        <Row>
          <Col>
            <p>No branches data available.</p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default RBranchesPage;
