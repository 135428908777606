import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function Footer(){
    const currentYear = new Date().getFullYear();
    return ( 

    <Container fluid="true" className="footer">
    
        <Row>
      <Col md="2">
          <h3 id="footerbottomtext">
          <a href="/contactus" className="privacypolicy">| Contact Us |</a>
          </h3>
        </Col>
        <Col md="10">
          <h3 id="footerbottomtext">
           Copyright &copy; {currentYear} MenusbyV - All Rights Reserved - Powered by <a href="https://www.virosag.com" className="privacypolicy">Virosag</a> - <a href="privacypolicy" className="privacypolicy">Privacy Policy</a> - <a href="termsofservice" className="privacypolicy">Terms of Service</a>
          </h3>
        </Col>
      </Row>
 

    </Container>

   
    )
};


export default Footer;

